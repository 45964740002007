import React from 'react'
import Typed from 'react-typed'

const Banner = (props) => (
  <section id="banner" className="major">
    <div className="inner">
      <header className="major">
        <h1>
          I can help you create
          <br />
          <span>
            <Typed
              strings={['performing', 'testable', 'unique']}
              typeSpeed={70}
              backSpeed={50}
              showCursor={false}
              startDelay={300}
            />
            <br />
            software
          </span>
        </h1>
        <ul className="actions">
          <li>
            <a href="#portfolio" className="button scrolly">
              See Portfolio
            </a>
          </li>
        </ul>
      </header>
    </div>
  </section>
)

export default Banner
