import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/images/pic01.png'
import pic02 from '../assets/images/pic02.png'
import pic04 from '../assets/images/pic04.png'
import pic03 from '../assets/images/pic03.png'

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Kamil Pastuszka - JavaScript Developer"
          description="I build websites and applications using modern technologies"
          meta={[
            {
              name: 'description',
              content:
                'I build websites and applications using modern technologies',
            },
            {
              name: 'keywords',
              content: 'JavaScript, React, Node, Gatsby, Graphql',
            },
          ]}
        />

        <Banner />

        <div id="main">
          <section id="portfolio" className="tiles">
            <article style={{ backgroundImage: `url(${pic01})` }}>
              <header className="major">
                <h3>Interest Rate Calculator</h3>
                <p>
                  An application that calculate overall and monthly interest
                  payments
                </p>
                <br />
                <a
                  className="button"
                  href="https://kamilpastuszka.github.io/react-redux-interest-calculator/"
                >
                  see on github
                </a>
              </header>
              {/* <Link to="/landing" className="link primary" /> */}
            </article>

            <article style={{ backgroundImage: `url(${pic03})` }}>
              <header className="major">
                <h3>Data Visualization</h3>
                <p>
                  A graph showing recent (as of 2017) and predicted (for 2050)
                  population of biggest EU states.
                </p>
                <br />
                <a
                  className="button"
                  href="https://kamilpastuszka.github.io/vue-chartjs/"
                >
                  see on github
                </a>
              </header>
            </article>

            <article style={{ backgroundImage: `url(${pic04})` }}>
              <header className="major">
                <h3>Image Gallery</h3>
                <p>
                  A responsive image gallery built with SASS and CSS Grid
                  technology
                </p>
                <br />
                <a
                  className="button"
                  href="https://kamilpastuszka.github.io/responsive-css-grid-gallery/"
                >
                  see on github
                </a>
              </header>
            </article>

            <article style={{ backgroundImage: `url(${pic02})` }}>
              <header className="major">
                <h3>Temperature graph</h3>
                <p>
                  Temperature graph example showing monthly breakdown of
                  temperatures for London.
                </p>
                <br />
                <a
                  className="button"
                  href="https://kamilpastuszka.github.io/react-hooks-data-visualization/"
                >
                  see on github
                </a>
              </header>
              <br />
              <a className="button" href="" />
            </article>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
